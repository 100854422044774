import React, { Component } from "react";
import Title from "../common/Title/title";
import "./settings.css"
import SettingsInput from "./settingsInput";
import { getMe, UpdateMe, comparePassword } from "../../services/userServices";
import Joi from "../../../node_modules/joi-browser";

class Settings extends Component {

state={
    edit:false,
    type:"password",
    user:{
        Name:"",
        Password:"",
        Email:""
    },
    oldPassword:"",
    newPassword:"",
    confirmPassword:"",
    errors:{}
}

schema = {
    Name: Joi.string().required(),
    Email: Joi.string().email().required(),
    Password:Joi.string()
  };

  mapToViewModel(user) {
    return {
      Name: user.user_name,
      Email: user.email,
      Password: user.password
    };
  }

async componentDidMount()
{
    const user=await getMe();
    if(user)
    {
    const oldPassword=user.Password? user.Password:"";
    this.setState({ user: this.mapToViewModel(user) });
    this.setState({oldPassword});
    }
}

onOldPasswordChange=(event)=>{

    const oldPassword=event.target.value;
    this.setState({oldPassword});
}
onNewPasswordChange=(event)=>{
    
    const newPassword=event.target.value;
    this.setState({newPassword});
}

onConfirmPasswordChange=(event)=>{
    const confirmPassword=event.target.value;
    this.setState({confirmPassword});
}
onclick=()=>{
    let edit=!this.state.edit;
    this.setState({edit});
}


validate = () => {
    const { error } = Joi.validate(this.state.user, this.schema);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
};

async validatepassword (oldPassword)  {
    const errors = {};
    let valid=await comparePassword(oldPassword,this.state.token);
    if( valid==="true")
    {
        delete errors["Password"];
        this.setState({errors}); 
        if(this.state.confirmPassword===this.state.newPassword)
        {
            delete errors["Password"];
            this.setState({errors}); 
            return true;
        }
        else
        {
            errors["Password"]="Confirmed password doesn't match ";
            this.setState({errors});
        }
        
    }
    else
    {
        errors["Password"]="Old password doesn't match";
        this.setState({errors});
    }
    
};

onChange=(event,name)=>{
 
    let user="";
    if(name==="Name")
    {
        user={  Name:event.target.value,
        Password:this.state.user.Password,
        Email:this.state.user.Email}
    }
    this.setState({user});
}


async doSubmit(){
    let errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) 
    {
        console.log(errors);
        return;
    } 
    if(this.state.edit===true && this.state.oldPassword!=="" && this.state.newPassword!=="" && this.state.confirmPassword!=="" )
    {
       if( this.validatepassword(this.state.oldPassword))
       {  
        try{
            let user ={...this.state.user};
            user.Password=this.state.newPassword;
            this.setState({user}, ()=>{  UpdateMe(this.state.user)});
        }
        catch(e){
            console.log(e);
        }
       }
    }
    
   
}

render()
{
    return(
        <React.Fragment>
            <main className="mainstyle  col-lg-9 col-md-8 col-sm-7 mt-4 mb-4" id="details-scroll">
            <Title title="Account Information"></Title>
            {/* <div className="pl-4 info-div">Account information</div> */}
            <div className="row  pl-4">
                <div className="mt-3 col-lg-6 col-md-6 col-12">
                    <SettingsInput type="text" 
                                   label="Name" 
                                   onChange={this.onChange} 
                                   error={this.state.errors.Name} 
                                   value={this.state.user.Name}></SettingsInput>
                </div>
                <div className="mt-3 col-lg-6 col-md-6 col-12">
                    <SettingsInput type="email"  
                                   label="Email"  
                                   onChange={this.onChange} 
                                   error={this.state.errors.Email} 
                                   value={this.state.user.Email}>
                    </SettingsInput>
                </div>
            </div>
            <div className="row  pl-4">
                <div className="mt-4 col-lg-6 col-md-6 col-12">
                    <SettingsInput type={this.state.type} 
                                   edit={this.state.edit} 
                                   onclick={this.onclick}  
                                   onOldChange={this.onOldPasswordChange} 
                                   error={this.state.errors.Password}
                                   onNewChange={this.onNewPasswordChange} 
                                   onConfirmChange={this.onConfirmPasswordChange}
                                   label="Password" placeholder={this.state.edit? "Old Password" : "******"}>
                    </SettingsInput>
                </div>
            </div>
            <div className="  pl-4">
                <button className="mt-5 col-lg-2 col-3 btn-settings" onClick={()=>this.doSubmit()}>
                    Save
                </button>
                
            </div>
            </main>
        </React.Fragment>
    )
}

}

export default Settings;