import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table/table"
import {getCampaign, deleteCampaign} from "../../services/campaignServices"
import Title from "../common/Title/title";
import ClipLoader from "react-spinners/ClipLoader";
class Campaign extends Component {
  columns = [
    {
      path: "campaign_header",
      label: "Title",
      content: campaign => <Link className="anchor-table" to={`/campaign/${campaign.id}`}>{campaign.campaign_header}</Link>
    },
    { path: "is_published", label: "Publish" },
    { path: "creation_timstamp", label: "Creation Date" },
    {
      label:"edit",
      key: "edit",
      content: campaign => <Link to={`/campaign/${campaign.id}`} className="edit-a"><div className="edit-box"><img alt="edit" src={require("../../assets/images/edit.svg")} /> </div></Link>
    },
    {
      
      label: "Delete",
      key: "delete",
      content: campaign => (<button onClick={()=>{this.hanldeDelete(campaign.id)}} className="edit-box"><img alt="delete" className="mb-1" src={require("../../assets/images/delete-row.svg")} /> </button>)
    }
  ];

    state = {
      campaign:["",""],
      currentPage: 1,
      pageSize: 4,
      searchQuery: "",
      autoComplete:[],
      loading:true
    };

    async componentDidMount() {
      const campaign=await getCampaign("");
      if (campaign)
      this.setState({campaign},()=>{this.setState({loading:false})});
    }

    async hanldeDelete(id){
      let confirm=window.confirm(`Are you sure you want to delete this campaign record?`);
      if(confirm)
      {
        const originalCampaign = this.state.campaign;
        const campaign = originalCampaign.filter(n => n.id !== id);
        this.setState({ campaign });
        await deleteCampaign(id);
      }
    }

    async onSearchChange(event){
      const campaign= await getCampaign(event.target.value);
      this.setState({campaign});
    }

    render() 
    {
      
        return (
          <React.Fragment>
            <main className="mainstyle  col-lg-9 col-md-8 col-sm-7 mt-4 mb-4" >
              <Title title="Heart Campaign" calling={"campaign"} onSearchChange={(e)=>{this.onSearchChange(e)}} autoComplete={this.state.autoComplete}>
              </Title>
              <div className="grid-display">
              <ClipLoader
                  size={50}
                  color={"#183D6E"}
                  loading={this.state.loading}
              />
              </div>
              <div className="ml-4">
                <div className="row"  hidden={ this.state.loading? true : false }>
                {this.state.campaign.length>0? <Table columns={this.columns} data={this.state.campaign}/>:<div className="centerdiv col-12 anchor-table">There is no campaigns avilable</div>}
                </div>
              </div>
            </main>
          </React.Fragment>
        );
    }

}
export default Campaign;