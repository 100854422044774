import React, {Component} from "react";
import './logout.css';
import { getMe,removeToken} from "../../services/userServices";
import store from "../../../node_modules/store2";

class Logout extends Component{

     state={ 
          user:{
               user_name:"",
               password:"",
               email:"",
               phone_number:""
          }
     }
async componentDidMount(){
     const user=await getMe();
     if(user)
     this.setState({user});
}

handleClick= ()=>{
this.props.onClick(false); 
const refresh_token= store.local.get('refresh_token');
try{
  removeToken(refresh_token);
}
catch(e)
{
     console.log(e);
     return;
}
store.local.remove('currentUser');
store.local.remove('refresh_token');
this.props.history.push("");
}

render(){
return (
    <React.Fragment>
       <div className="logout-div" >
           <div  className="top-div">
                <h6 className="name-header">{this.state.user.user_name}</h6>
                <div className="email-div">{this.state.user.email}</div>
           </div>
           <hr className="line-hr"/>
           <div  className="bottom-div" onClick={()=>{this.handleClick()}}>
                <img alt="logout" src={require("../../assets/images/logout.svg")} ></img>
                <span className="logout-span">Logout</span>
           </div>
       </div>
    </React.Fragment>
  );
}
}
export default Logout;
