import React, { Component } from "react";
import "./login.css"
import Input from "../common/form/input"
import { authenticate } from "../../services/userServices";
import store from "../../../node_modules/store2";
import Joi from "../../../node_modules/joi-browser";

class Login extends Component {

state={
    user:{
        password:"",
        email:""
    },
    errors:{}
}


schema = {
    email: Joi.string().email().required(),
    password:Joi.string().required()
  };


validate = () => {
    const { error } = Joi.validate(this.state.user, this.schema);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
};

async validatepassword (password,email)  {
    const errors = {};
    let valid=await authenticate(password,email);
    if( valid)
    {
        
        delete errors["password"];
        this.setState({errors});
        store.local.set('currentUser', valid.token);
        store.local.set('refresh_token', valid.refresh_token);
        return true;
    }
    else
    {
        errors["password"]="Invalid email or password";
        this.setState({errors});
        return false;
    }
    
};

onChange=(event,name)=>{
 
     let user="";
     if(name==="email")
    {
        user={
        password:this.state.user.password,
        email:event.target.value,
       }
    }
    else
    {
        user={  
            password:event.target.value,
            email:this.state.user.email,
            }
    }

    
     this.setState({user});
   
}

async doSubmit(){

    let errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) 
    {
        console.log(errors);
        return;
    } 
    if( await this.validatepassword(this.state.user.password, this.state.user.email))
    {

        this.props.onChange(true);
        this.props.history.push("/home");
       // window.location= this.props.history.location;
    }
       
    
}

render()
{
    return(
        <React.Fragment>
            <div className="container">
            <div className="center-btn full-width">   
                <img alt="heal a heart" className="login-img" src={require("../../assets/images/Heal a Heart_Final.svg")} /> 
            </div>   
            <div className=" login-container full-width">
                <div className=" pl-4 pr-4 pt-3 m-4 login-box" >
                <p className="sign-in">SIGN IN</p>
                <hr ></hr>
                <div >
                <Input
                        type="email"
                        name="email"
                        value={this.state.user.email}
                        label="Email"
                        onChange={(event)=>this.onChange(event,"email")}
                        error={this.state.errors.email}
                    />
                </div>
                <div>
                <Input
                    type="password"
                    name="password"
                    value={this.state.user.password}
                    label="Password"
                    onChange={(event)=>this.onChange(event,"password")}
                    error={this.state.errors.password}
                />  
                </div>
                
                <div className="center-btn full-width">
                <button className="full-width  btn-settings pl-5 pr-5" onClick={()=>this.doSubmit()}>
                    Login
                </button>
                </div>
            </div> 
            </div>
           
            
            </div>
        </React.Fragment>
    )
}

}

export default Login;