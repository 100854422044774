import React from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import {getCampaignDetails,updateCampaign, addCampaign} from "../../services/campaignServices"
import Title from "../common/Title/title";

class CampaignForm extends Form {
  state = {
    data: { Title: "",
            Body: "",
            is_featured:false,
            is_listing_feature:false,
            campaign_image:null,
            is_published:false
        },
    isFound: true,
    errors: {}
  };

  schema = {
    Title: Joi.string().required(),
    Body: Joi.string().required(),
    is_featured: Joi.boolean().required(),
    is_listing_feature:Joi.boolean().required(),
    is_published:Joi.boolean().required(),
    campaign_image:Joi.any()
  };

   doSubmit = async () => {
    let confirm=window.confirm("Are you sure you want to proceed?");
     try{
          if(confirm)
          {
            if(this.props.match.params.id !== "new")
            await  updateCampaign(this.props.match.params.id,this.state.data);
            else
            await  addCampaign(this.state.data);

            this.props.history.push("/campaign");
          }
     }
     catch(e){
            console.log(e);
     }
  };

  async componentDidMount() {
    const campaignId = this.props.match.params.id;
    if (campaignId === "new") return;

    const campaign = await getCampaignDetails(campaignId);
    if(campaign == null)
    {
      this.setState({isFound:false})
      return 
    }
    this.setState({ data: this.mapToViewModel(campaign) });

       
  }

  mapToViewModel(campaign) {
    return {
      Title: campaign.campaign_header,
      Body: campaign.campaign_details,
      campaign_image: campaign.campaign_image,
      is_featured:campaign.is_featured,
      is_listing_feature:campaign.is_listing_feature,
      is_published:campaign.is_published
    };
  }

  render() {
    if(this.state.isFound)
    return (
      <React.Fragment>
        <main className="mainstyle  col-lg-9 col-md-8 col-sm-7 mt-4 mb-4" id="details-scroll" >
      <Title title="Heart Campaign"></Title>
      <div className="mr-4 ml-4">
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("Title", "Title", "text","Add title here")}
          {this.renderInput("Body", "Body text","","Add body text here")}
          {this.renderInput("campaign_image",  "Featured image", "file")}
          <div className="row">
          {/* {this.renderInput("is_featured",  "Feature in homepage",  "checkbox")}
          {this.renderInput("is_listing_feature",  "Feature in Listing page",  "checkbox")} */}
          {this.renderInput("is_published",  "Publish",  "checkbox")}
          </div>
          {this.props.match.params.id !== "new"? this.renderButton("Update") : this.renderButton("Add")}
        </form>
      </div>
      </main>
      </React.Fragment>
    );

   return <div>There is no such id</div>
  }
}

export default CampaignForm;
