import React, { Component } from "react";
import { Link } from "react-router-dom";
import './navBar.css';
import Logout from "../logOut/logout";


class NavBar extends Component {

state={
    marked:""
}





handleClick(name){
  
  if(name==="home")
  {
    let marked="whatsNew";
    this.setState({marked});
  }
  else if(name ==="whatsNew")
  {
    let marked="whatsNew"
    this.setState({marked});
  }
  else if(name ==="tips")
  {
    let marked="tips"
    this.setState({marked});
  }
  else if(name ==="settings")
  {
    let marked="settings"
    this.setState({marked});
  }
  else if(name ==="notifications")
  {
    let marked="notifications"
    this.setState({marked});
  }
  else
  {
    let marked="campaign"
    this.setState({marked});
  }
}

componentDidMount(){
  if(this.props.location.pathname ==='/settings')
  {
    const marked="settings";
    this.setState({marked});
  }
  else if(this.props.location.pathname ==='/campaign')
  {
    const marked="campaign";
    this.setState({marked});
  }
  else if(this.props.location.pathname ==='/whatsnew')
  {
    const marked="whatsNew";
    this.setState({marked});
  }
  else if(this.props.location.pathname ==='/tips')
  {
    const marked="tips";
    this.setState({marked});
  }
  else if(this.props.location.pathname ==='/notifications')
  {
    const marked="notifications";
    this.setState({marked});
  }
  else
  {
    const marked="whatsNew";
    this.setState({marked});
  }
}

componentDidUpdate(){

  window.onpopstate  = (e) => {
 if(this.props.location.pathname ==='/settings')
  {
    const marked="settings";
    this.setState({marked});
  }
  else if(this.props.location.pathname ==='/campaign')
  {
    const marked="campaign";
    this.setState({marked});
  }
  else if(this.props.location.pathname ==='/whatsnew')
  {
    const marked="whatsNew";
    this.setState({marked});
  }
  else if(this.props.location.pathname ==='/tips')
  {
    const marked="tips";
    this.setState({marked});
  }
  else if(this.props.location.pathname ==='/notifications')
  {
    const marked="notifications";
    this.setState({marked});
  }
  else
  {
    const marked="whatsNew";
    this.setState({marked});
  }
 }

    }

render(){
 
  if(this.props.loggedIn)
  return (
    <nav className=" col-lg-2 col-md-3 col-sm-4 mt-4 mb-4 nav-element">
       
        <div className="nav_div_link">
       
          <Link  className="nav-link nav-item nav_first_item" to="/home" onClick={()=>this.handleClick("home")}>
              <img alt="heal a heart" className="logo-img" src={require("../../assets/images/Heal a Heart_Final.svg")} /> 
          </Link>
          <Link className={this.state.marked ==="whatsNew"?"nav-item nav_other_item nav-link": "nav-item nav_other_item nav-link-oppacity"} to="/whatsnew" onClick={()=>this.handleClick("whatsNew")}>
              <img alt="news" className={this.state.marked==="whatsNew"?"img_item" : "img_item_oppacity"} src={require("../../assets/images/sound.svg")} /> 
               What's new
          </Link>
          <Link className={this.state.marked ==="tips"?"nav-item nav_other_item nav-link": "nav-item nav_other_item nav-link-oppacity"} to="/tips" onClick={()=>this.handleClick("tips")}>
              <img alt="tips" className={this.state.marked==="tips"?"img_item" : "img_item_oppacity"}  src={require("../../assets/images/heart.svg")} /> 
               Heart tips
          </Link>
          <Link className={this.state.marked ==="campaign"?"nav-item nav_other_item nav-link": "nav-item nav_other_item nav-link-oppacity"} to="/campaign" onClick={()=>this.handleClick("campaign")}>
              <img alt="campaign" className={this.state.marked==="campaign"?"img_item" : "img_item_oppacity"}  src={require("../../assets/images/heart.svg")} /> 
               Campaign
          </Link>
         
          <Link className={this.state.marked ==="notifications"?"nav-item nav_other_item nav-link": "nav-item nav_other_item nav-link-oppacity"} to="/notifications" onClick={()=>this.handleClick("notifications")}>
              <img alt="notifications" className={this.state.marked==="notifications"?"img_item" : "img_item_oppacity"}  src={require("../../assets/images/bell.svg")} /> 
               Push notifications
          </Link>
          <Link className={this.state.marked ==="settings"?"nav-item nav_other_item nav-link": "nav-item nav_other_item nav-link-oppacity"} to="/settings" onClick={()=>this.handleClick("settings")}>
              <img alt="settings" className={this.state.marked==="settings"?"img_item" : "img_item_oppacity"}  src={require("../../assets/images/settings.svg")} /> 
               Account Information
          </Link>
         
        </div>
        <Logout {...this.props} onClick={(logged)=>this.props.handleLogout(logged)}></Logout>
      
    </nav>
  );
  else return <div></div>
};
}
export default NavBar;
