import { apiUrl } from "../config.json";
import { post_,get_ } from "./axiosServices";


export async function getNews(value)
{
  let obj={
    value:value
  }
  const url=apiUrl+"/news/admin";
  const response = await post_(url,obj);
  return response.news;
}

export async function deleteNews(_id)
{
  const url=apiUrl+"/news/delete/admin";
  await post_(url, {id: _id});
  return;
}

export async function getNewsDetails(_id)
{
  const url=apiUrl+`/news/details/${_id}`;
  const response = await get_(url);
  return response.data_details;
}

export async function updateNews(_id, news)
{
  
  var convertedStartDate = new Date();
  var hour = convertedStartDate.getHours();
  var minute = convertedStartDate.getMinutes();
  var second = convertedStartDate.getSeconds();
  var day = convertedStartDate.getDate();
  var month = convertedStartDate.getMonth() + 1;
  var year = convertedStartDate.getFullYear();
  var fullDate = year + "-" + month + "-" + day + ' ' + hour + ":" + minute + ":" + second;
  

  const formData = new FormData();
  
  if(typeof(news["news_image"])!=="string" && news["news_image"]!== null)// check if image is updated
   formData.append('news_image', news["news_image"]);
   formData.append('news_header',news["Title"]);
   formData.append('news_details',news["Body"]);
   formData.append('is_featured',news["is_featured"]);
   formData.append('is_listing_feature',news["is_listing_feature"]);
   formData.append('last_modification_timestamp', fullDate);
   formData.append('is_published',news["is_published"]);

  const url=apiUrl+`/news/update/${_id}`;
  const response = await post_(url,formData);
  return response.updatedNews;
}

export async function addNews(news)
{
  var convertedStartDate = new Date();
  var hour = convertedStartDate.getHours();
  var minute = convertedStartDate.getMinutes();
  var second = convertedStartDate.getSeconds();
  var day = convertedStartDate.getDate();
  var month = convertedStartDate.getMonth() + 1;
  var year = convertedStartDate.getFullYear();
  var fullDate = year + "-" + month + "-" + day + ' ' + hour + ":" + minute + ":" + second;

  const formData = new FormData();
  
    if(typeof(news["news_image"])!="string" && news["news_image"]!= null)// check if image is updated
   formData.append('news_image', news["news_image"]);
   formData.append('news_header',news["Title"]);
   formData.append('news_details',news["Body"]);
   formData.append('is_featured',news["is_featured"]);
   formData.append('is_listing_feature',news["is_listing_feature"]);
   formData.append('is_published',news["is_published"]);
   formData.append('creation_timestamp', fullDate);

  const url=apiUrl+`/news`;
  const response = await post_(url,formData);
  return response.insertedNews;

}


// export async function getAutoComplete(value,token)
// {
  
//   const url=apiUrl+"/search/news";
 
//     try{
//     let res=await axios.post(url,{ value: value },{headers:{'x-auth-token':token}})
//     return res.data.autoComplete;
//     }
//     catch(e)
//     {

//       if(e.response)
//       {
//         if(e.response.status ===403)
//         {
//           store.local.remove('currentUser');
//           window.confirm("Session Expired");
//           window.location.reload();
//         }
//       }
//       return false;
//     }
// }

// export async function getFeaturedNews(token)
// {
 
//   const url=apiUrl+"/news/admin/featured";
//     try{
//     let res=await axios.get(url,{headers:{'x-auth-token':token}})
//     return res.data.news;
//     }
//     catch(e)
//     {
//       console.log(e);
//       if(e.response)
//       {
//         if(e.response.status ===403)
//         {
//           store.local.remove('currentUser');
//           window.confirm("Session Expired");
//           window.location.reload();
//         }
//       }
//       return false;
//     }

  
// }