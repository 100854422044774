import React, { Component } from "react";

import './form.css';
import 'font-awesome/css/font-awesome.min.css';


class Input extends Component {

  renderImage(value){
    let image="";
    if(value == null )
    {
      image= <img alt="" id="output" src={require("../../../assets/images/empty-img.png")} className="no-img"></img> 
    }
    else if(value!=null && typeof(value) === "string")
    {
    var url = "https://api.healaheart.org/"+value;
    image= <img alt="upload"  id="output"  src={url} className="output-img"></img>
    }
    else
    {
      url=URL.createObjectURL(value);
      image= <img  id="output" alt="upload" src={url} className="output-img"></img>
    }
    let htmlTag= <React.Fragment>  {image} <div><div className="recomendation_div">Minimum size</div><div className="size-div"> 1024 X 576 </div></div> </React.Fragment>
    return htmlTag;
  }
  
   renderCell( type, name, label, error, value, onChange,onClick ,loadFile, handleInputClick,placeholder){
     
    if(name.includes("details") || name.includes("Body"))
    return <textarea value={value} placeholder={placeholder} onChange={this.props.onChange} name={name} id={name} className="input-body" />

    else if(name.includes("image"))
    return <React.Fragment>
            <input  type={type} id="img-file" className="input-file"  onChange={(event)=>{loadFile(event,name)}}  accept="image/*"></input>
            <div className="img-input" onClick={handleInputClick}>
            {this.renderImage(value)}
            </div>
            <span className="img-info">Click the image to upload</span>
          </React.Fragment>

    else if(name === "is_featured" || name === "is_listing_feature" || name ==="is_published")
    return <React.Fragment>
          <input type={type} checked={value}  value={value} onChange={onChange}  className="inp-checkbox check-mark"  name={name} id={name}  />
          </React.Fragment>

    else
     return <input type={type} placeholder={placeholder} value={value} onChange={onChange} name={name} id={name} className="input-title" />
  }

  render(){
    
    let { type,name, label, error, value ,onChange,onClick, loadFile, handleInputClick,placeholder }= this.props;
    
    if(name === "is_featured" || name === "is_listing_feature" || name === "is_published")
    { 
        return (
          <div className="form-group col-4">
            <label className="label-form " htmlFor={name}>{label}</label>
            {this.renderCell(type,name, label, error, value  ,onChange,onClick, loadFile,handleInputClick,placeholder)}
            {error && <div className="alert alert-danger">{error}</div>}
          </div>
        );
    }
  return (
    <div className="form-group ">
      <label className="label-form" htmlFor={name}>{label}</label>
      {this.renderCell(type,name, label, error, value ,onChange,onClick,loadFile, handleInputClick,placeholder)}
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );}
};

export default Input;
