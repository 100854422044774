import { apiUrl } from "../config.json";
import { post_ } from "./axiosServices";


export async function pushNotification(title,body)
{
  
  const url=apiUrl+"/push-notification";
  let obj={
    title:title,
    body:body
    }

    return await post_(url, obj);
  
}