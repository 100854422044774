import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import './table.css';

const Table = ({ columns, data }) => {
  return (

    
   
    <table className="table row col-12">

      <TableHeader columns={columns}  />
     
      <TableBody  columns={columns} data={data} />

    </table>
 
    
  );
};

export default Table;
