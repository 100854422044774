import React, { Component } from "../../../../node_modules/react";
import Joi from "../../../../node_modules/joi-browser";
import Input from "./input";
import './form.css';
import AddField from "./addField";
//import Select from "./select";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
   
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  

  handleChange = ({ currentTarget: input }) => {
    
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

   
    const data = { ...this.state.data };

    if(input.type ==="checkbox")
    {
    data[input.name] = !(input.value ==="true");
    
    }
    else{
      data[input.name] = input.value;
    }
    this.setState({ data, errors });
  };

  renderButton(label) {
    return (
      <button onClick={this.handleSubmit} className="input-btn">
        {label}
      </button>
    );
  }



  loadFile = (event,name)=>{
    const errors = { ...this.state.errors };
    if(event.target.files[0])
    {
      if(event.target.files[0].name.includes(" "))
      {
        errors[name] = "File name should contain no spaces";
        this.setState({ errors });
        return;
      }
      else delete errors[name];
      this.setState({ errors });
      var image = document.getElementById('output');
      try{
        const data = { ...this.state.data };
        data[name] =  event.target.files[0];
        
        this.setState({data});
        image.src = URL.createObjectURL(event.target.files[0]);
  
      }
      catch(error)
      {
        console.log(error);
      }
    } 
    
   }
  

   handleInputClick(){
    document.getElementById("img-file").click();
   }
   
   

  // renderSelect(name, label, options) {
  //   const { data, errors } = this.state;

  //   return (
  //     <Select
  //       name={name}
  //       value={data[name]}
  //       label={label}
  //       options={options}
  //       onChange={this.handleChange}
  //       error={errors[name]}
  //     />
  //   );
  // }

  renderInput(name, label, type, placeholder) {
    const { data, errors } = this.state;
    
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onClick={this.handleClick}
        error={errors[name]}
        loadFile={this.loadFile}
        handleInputClick={this.handleInputClick}
        placeholder={placeholder}
      />
    );
  }


  renderAddfield(name,label, btnName, placeholderTitle, onnameChange,onurlchange, sourceClick, onRemoveClick, newSource) {
    const {  data,errors } = this.state;
    let sourceList="";
    
    if(data.Source)
    {
    sourceList = data.Source.map((source, i) => { 
    return <li className="source-li"title={source.source_url}  key={i}>
              <a className="source-name" href={source.source_url} target="_blank"  rel="noopener noreferrer">{source.source_name}</a> <i onClick={()=>{onRemoveClick(i)}} className="ml-2 fa fa-times  removeButton"></i>
           </li>;});
    }
    return (
      <React.Fragment>
        <AddField
          value={newSource}
          label={label}
          onNameChange={onnameChange}
          onUrlChange={onurlchange}
          onClick={(e)=>{sourceClick(e)}}
          error={errors[name]}
          btnName={btnName}
          placeholder={placeholderTitle}
        />

        <ul className="addfield-ul col-12 row">{sourceList}</ul>
    </React.Fragment>
    );
  }
}




export default Form;
