import React from "react";
import './title.css';
import SearchBox from "../../common/searchBox/searchBox";
import { Link } from "react-router-dom";
const Title = props => {

  return (
    <React.Fragment>
    <div className=" title-div" >
        <div className="row">
        <div className="whatsNewTitle ">
                {props.title}
        </div>
        {props.calling? <div className="search-div ">
                          <SearchBox calling={props.calling} onSearchChange={props.onSearchChange} autoComplete={props.autoComplete}>
                          </SearchBox>
                        </div> :  <div className="search-div"></div>
        }
        </div>
        {props.calling? <div className="btn-div ">
                            <Link to={`/${props.calling}/new`}>
                                    <button className="m-0 p-2 btn_search" >Add New</button>
                            </Link>
                        </div> : <div className="btn-div"></div>
        }
            
        
    </div>

    <hr className="mt-0 hr-color"></hr>
    </React.Fragment>
  );
};

export default Title;






