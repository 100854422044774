import React from "react";
import Joi from "joi-browser";
import Form from "../common/form/form";
import {getTipsDetails,updateTips, addTips} from "../../services/tipsServices"
import Title from "../common/Title/title";

class TipsForm extends Form {
  state = {
    data: { Title: "",
            Body: "",
            is_featured:false,
            is_listing_feature:false,
            tips_image:null,
            is_published:false,
            Source:[]
        },
    newSource:{
      source_name:"",
      source_url:""
    },
    isFound: true,
    errors: {}
  };

  schema = {
    Title: Joi.string().required(),
    Body: Joi.string().required(),
    is_featured: Joi.boolean().required(),
    is_listing_feature:Joi.boolean().required(),
    is_published:Joi.boolean().required(),
    tips_image:Joi.any(),
    Source:Joi.any()
  };

   doSubmit = async () => {
     try{
        let confirm=window.confirm("Are you sure you want to proceed?");
        if(confirm)
        {
            if(this.props.match.params.id !== "new")
              await  updateTips(this.props.match.params.id,this.state.data);
            else
              await  addTips(this.state.data);
              this.props.history.push("/tips");
        }
     }
     catch(e){
            console.log(e);
     }
  };

  async componentDidMount() {
    const tipsId = this.props.match.params.id;
    if (tipsId === "new") return;

    const tips = await getTipsDetails(tipsId);
    
    if(tips == null)
    {
      this.setState({isFound:false})
      return 
    }

    this.setState({ data: this.mapToViewModel(tips) });

       
  }
  sourceNameChange=({target})=>{
    const newSource={...this.state.newSource};
    newSource.source_name=target.value;
    this.setState({newSource});
  }
  sourceUrlChange=({target})=>{
    const newSource={...this.state.newSource};
    newSource.source_url=target.value;
    this.setState({newSource});
  }
  sourceClick=()=>{
    
    let expression=/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    var regex = new RegExp(expression);
   

    const errors = { ...this.state.errors };
    if(!this.state.newSource.source_name||!this.state.newSource.source_url)
    {
      errors["Source"] = "Source name and url are required";
      this.setState({ errors: errors || {} });
      return errors;
    }
    else {
      delete errors["Source"];
      if(!this.state.newSource.source_url.match(regex))
      {
        errors["Source"] = `URL is not valid.`;
        this.setState({ errors: errors || {} });
        return errors;
      }
      if(this.state.data.Source.find(element => element.source_url === this.state.newSource.source_url))/////////////////////
      {
        errors["Source"] = `Source URL must be unique`;
        this.setState({ errors: errors || {} });
        return errors;
      }
      this.setState({ errors: errors || {} });
    }
 
    
    
    const newSourceList = [...this.state.data.Source, this.state.newSource];
    const data={...this.state.data};
    data.Source=newSourceList;
    this.setState({data});
    const newSource ={...this.state.newSource};
    newSource.source_url="";
    newSource.source_name="";
    this.setState({newSource});
  }

  onRemoveClick=(i)=>{
    //debugger;
     
      let data={...this.state.data};
      data.Source.splice(i,1);
      this.setState({data});
     
  }

  mapToViewModel(tips) {
    return {
      Title: tips.tips_header,
      Body: tips.tips_details,
      tips_image: tips.tips_image,
      is_featured:tips.is_featured,
      is_listing_feature:tips.is_listing_feature,
      is_published:tips.is_published,
      Source:tips.tip_source
    };
  }

  render() {
    if(this.state.isFound)
    return (
      <React.Fragment>
        <main className="mainstyle  col-lg-9 col-md-8 col-sm-7 mt-4 mb-4" id="details-scroll" >
      <Title title="Heart Tips"></Title>
      <div className="mr-4 ml-4">
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("Title", "Title", "text","Add title here")}
          {this.renderAddfield("Source","Source","Add Source","Add Source",this.sourceNameChange,this.sourceUrlChange,this.sourceClick, this.onRemoveClick, this.state.newSource)}
          {this.renderInput("Body", "Body text","","Add body text here")}
          {this.renderInput("tips_image",  "Featured image", "file")}
          <div className="row">
          {this.renderInput("is_featured",  "Feature in homepage",  "checkbox")}
          {this.renderInput("is_listing_feature",  "Feature in Listing page",  "checkbox")}
          {this.renderInput("is_published",  "Publish",  "checkbox")}
          </div>
          {this.props.match.params.id !== "new"? this.renderButton("Update") : this.renderButton("Add")}
        </form>
      </div>
      </main>
      </React.Fragment>
    );

   return <div>There is no such id</div>
  }
}

export default TipsForm;
