import { apiUrl } from "../config.json";
import { post_,get_ } from "./axiosServices";
import axios from "axios";

export async function getMe()
{
  const url=apiUrl+"/users/me";
  const response = await get_(url);
  return response.currentUser;
}

export async function UpdateMe(user)
{
  let obj={
    name:user.Name,
    email:user.Email,
    password:user.Password
  }
  const url=apiUrl+"/users/update";
  let response = await post_(url, obj);
  return response.updatedUser;
}

export async function comparePassword(password)
{
  
  let obj={
    password:password
  }
  const url=apiUrl+"/users/oldpassword";
  let response = await post_(url, obj);
  return response.valid;
}

export async function removeToken(refresh_token){
  let obj={
    token: refresh_token
  }
  const url=apiUrl+"/token/delete";
  let res = await axios.post(url,obj);
  return res;
}

export async function authenticate(password,email)
{
  let obj={
    password:password,
    email:email
  }
  const url=apiUrl+"/auth";
  try{
    let res=await axios.post(url,obj)
    if(res.data.status === 400)
    {
      return null;
    }
    return {token:res.data.token, refresh_token: res.data.refresh_token};
  }
  catch(e)
  {
    console.log(e);
    return false;
  }
}


