import { apiUrl } from "../config.json";
import { post_,get_ } from "./axiosServices";

export async function getCampaign(value)
{
  let obj={
    value:value
  }
  const url=apiUrl+"/campaign/admin";
  const response = await post_(url,obj);
  return response.campaign;
}

export async function deleteCampaign(_id)
{
  const url=apiUrl+"/campaign/delete/admin";
  await post_(url, {id: _id});
  return;
}

export async function getCampaignDetails(_id)
{
  const url=apiUrl+`/campaign/details/${_id}`;
  const response = await get_(url);
  return response.data_details;
}

export async function updateCampaign(_id, campaign)
{
  var convertedStartDate = new Date();
  var hour = convertedStartDate.getHours();
  var minute = convertedStartDate.getMinutes();
  var second = convertedStartDate.getSeconds();
  var day = convertedStartDate.getDate();
  var month = convertedStartDate.getMonth() + 1;
  var year = convertedStartDate.getFullYear();
  var fullDate = year + "-" + month + "-" + day + ' ' + hour + ":" + minute + ":" + second;
  

  const formData = new FormData();
  
  if(typeof(campaign["campaign_image"])!=="string" && campaign["campaign_image"]!== null)// check if image is updated
   formData.append('campaign_image', campaign["campaign_image"]);
   formData.append('campaign_header',campaign["Title"]);
   formData.append('campaign_details',campaign["Body"]);
   formData.append('is_featured',campaign["is_featured"]);
   formData.append('is_listing_feature',campaign["is_listing_feature"]);
   formData.append('is_published',campaign["is_published"]);
   formData.append('last_modification_timestamp', fullDate);


  const url=apiUrl+`/campaign/update/${_id}`;
  const response = await post_(url,formData);
  return response.updatedCampaign;
}

export async function addCampaign(campaign)
{

  var convertedStartDate = new Date();
  var hour = convertedStartDate.getHours();
  var minute = convertedStartDate.getMinutes();
  var second = convertedStartDate.getSeconds();
  var day = convertedStartDate.getDate();
  var month = convertedStartDate.getMonth() + 1;
  var year = convertedStartDate.getFullYear();
  var fullDate = year + "-" + month + "-" + day + ' ' + hour + ":" + minute + ":" + second;
  const formData = new FormData();
  
    if(typeof(campaign["campaign_image"])!="string" && campaign["campaign_image"]!= null)// check if image is updated
   formData.append('campaign_image', campaign["campaign_image"]);
   formData.append('campaign_header',campaign["Title"]);
   formData.append('campaign_details',campaign["Body"]);
   formData.append('is_featured',campaign["is_featured"]);
   formData.append('is_listing_feature',campaign["is_listing_feature"]);
   formData.append('is_published',campaign["is_published"]);
   formData.append('creation_timestamp', fullDate);
                    
  const url=apiUrl+`/campaign`;
  const response = await post_(url,formData);
  return response.insertedcampaign;
}


// export async function getAutoComplete(value,token)
// {
//   const url=apiUrl+"/search/campaign";
 
//     try{
//     let res=await axios.post(url,{ value: value },{headers:{'x-auth-token':token}})
//     return res.data.autoComplete;
//     }
//     catch(e)
//     {
//       console.log(e);
//       if(e.response)
//       {
//         if(e.response.status ===403)
//         {
//           store.local.remove('currentUser');
//           window.confirm("Session Expired");
//           window.location.reload();
//         }
//       }
//       return false;
//     }
// }
