import { apiUrl } from "../config.json";
import { post_,get_ } from "./axiosServices";

export async function getTips(value)
{
  let obj={
    value:value
  }
  const url=apiUrl+"/tips/admin";
  const response = await post_(url,obj);
  return response.tips;
  
}

export async function deleteTips(_id)
{
  const url=apiUrl+"/tips/delete/admin";
  await post_(url, {id: _id});
  return;
}

export async function getTipsDetails(_id)
{
  const url=apiUrl+`/tips/details/${_id}`;
  const response = await get_(url);
  return response.data_details;
}


export async function updateTips(_id, tips)
{
  var convertedStartDate = new Date();
  var hour = convertedStartDate.getHours();
  var minute = convertedStartDate.getMinutes();
  var second = convertedStartDate.getSeconds();
  var day = convertedStartDate.getDate();
  var month = convertedStartDate.getMonth() + 1;
  var year = convertedStartDate.getFullYear();
  var fullDate = year + "-" + month + "-" + day + ' ' + hour + ":" + minute + ":" + second;
  

  const formData = new FormData();
  
  if(typeof(tips["tips_image"])!=="string" && tips["tips_image"]!= null)// check if image is updated
   formData.append('tips_image', tips["tips_image"]);
   formData.append('tips_header',tips["Title"]);
   formData.append('tips_details',tips["Body"]);
   formData.append('is_featured',tips["is_featured"]);
   formData.append('is_listing_feature',tips["is_listing_feature"]);
   formData.append('is_published',tips["is_published"]);
   formData.append('last_modification_timestamp', fullDate);
   formData.append('source', JSON.stringify(tips["Source"]));

  const url=apiUrl+`/tips/update/${_id}`;
  const response = await post_(url,formData);
  return response.updatedTips;
}

export async function addTips(tips,token)
{
  var convertedStartDate = new Date();
  var hour = convertedStartDate.getHours();
  var minute = convertedStartDate.getMinutes();
  var second = convertedStartDate.getSeconds();
  var day = convertedStartDate.getDate();
  var month = convertedStartDate.getMonth() + 1;
  var year = convertedStartDate.getFullYear();
  var fullDate = year + "-" + month + "-" + day + ' ' + hour + ":" + minute + ":" + second;
  const formData = new FormData();
    if(typeof(tips["tips_image"])!=="string" && tips["tips_image"]!= null)// check if image is updated
   formData.append('tips_image', tips["tips_image"]);
   formData.append('tips_header',tips["Title"]);
   formData.append('tips_details',tips["Body"]);
   formData.append('is_featured',tips["is_featured"]);
   formData.append('is_listing_feature',tips["is_listing_feature"]);
   formData.append('is_published',tips["is_published"]);
   formData.append('creation_timestamp', fullDate);
   formData.append('source', JSON.stringify(tips["Source"]));

  const url=apiUrl+`/tips`;
  const response = await post_(url,formData);
  return response.insertedtips;
  }


// export async function getAutoComplete(value,token)
// {
//   const url=apiUrl+"/search/tips";

//     try{
//     let res=await axios.post(url,{ value: value },{headers:{'x-auth-token':token}})
//     return res.data.autoComplete;
//     }
//     catch(e)
//     {
//       console.log(e);
//       if(e.response)
//       {
//         if(e.response.status ===403)
//         {
//           store.local.remove('currentUser');
//           window.confirm("Session Expired");
//           window.location.reload();
//         }
//       }
//       return false;
//     }
// }

// export async function getFeaturedTips(token)
// {
//   const url=apiUrl+"/tips/admin/featured";
//     try{
//     let res=await axios.get(url,{headers:{'x-auth-token':token}})
//     return res.data.tips;
//     }
//     catch(e)
//     {
//       console.log(e);
//       if(e.response)
//       {
//         if(e.response.status ===403)
//         {
//           store.local.remove('currentUser');
//           window.confirm("Session Expired");
//           window.location.reload();
//         }
//       }
//       return false;
//     }

  
// }