import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table/table"
import {getTips, deleteTips} from "../../services/tipsServices"
import Title from "../common/Title/title";
import ClipLoader from "react-spinners/ClipLoader";
class Tips extends Component {
  columns = [
    {
      path: "tips_header",
      label: "Title",
      content: tips => <Link className="anchor-table" to={`/tips/${tips.id}`}>{tips.tips_header}</Link>
    },
    { path: "is_published", label: "Publish" },
    { path: "creation_timstamp", label: "Creation Date" },
    {
      label:"edit",
      key: "edit",
      content: tips => <Link to={`/tips/${tips.id}`} className="edit-a"><div className="edit-box"><img alt="edit" src={require("../../assets/images/edit.svg")} /> </div></Link>
    },
    {
      
      label: "Delete",
      key: "delete",
      content: tips => (<button onClick={()=>{this.hanldeDelete(tips.id)}} className="edit-box"><img alt="delete" className="mb-1" src={require("../../assets/images/delete-row.svg")} /> </button>)
    }
  ];

    state = {
      tips:["",""],
      currentPage: 1,
      pageSize: 4,
      searchQuery: "",
      autoComplete:[],
      loading:true
    };

    async componentDidMount() {
      const tips=await getTips("");
      if(tips)
      this.setState({tips},()=>{this.setState({loading:false})});
    }

    async hanldeDelete(id){
      let confirm=window.confirm(`Are you sure you want to delete this tip record?`);
      if(confirm)
      {
        const originalTips = this.state.tips;
        const tips = originalTips.filter(n => n.id !== id);
        this.setState({ tips });
        await deleteTips(id);
      }
    }

    async onSearchChange(event){
      const tips= await getTips(event.target.value);
      this.setState({tips});
    }

    render() 
    {
      
        return (
          <React.Fragment>
            <main className="mainstyle  col-lg-9 col-md-8 col-sm-7 mt-4 mb-4" >
              <Title title="Heart Tips"  calling={"tips"} onSearchChange={(e)=>{this.onSearchChange(e)}} autoComplete={this.state.autoComplete}>
              </Title>
              <div className="grid-display">
              <ClipLoader
                  size={50}
                  color={"#183D6E"}
                  loading={this.state.loading}
              />
              </div>
              <div className="ml-4">
                <div className="row " hidden={ this.state.loading? true : false } >
                {this.state.tips.length>0? <Table columns={this.columns} data={this.state.tips}/>:<div className="centerdiv col-12 anchor-table">There is no tips avilable</div>}
                </div>
              </div>
            </main>
          </React.Fragment>
        );
    }

}
export default Tips;