import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table/table"
import './whatsNew.css';
import {getNews, deleteNews} from "../../services/newsServices"
import Title from "../common/Title/title";
import ClipLoader from "react-spinners/ClipLoader";

class WhatsNew extends Component {
  columns = [
    {
      path: "news_header",
      label: "Title",
      content: news => <Link className="anchor-table" to={`/news/${news.id}`}>{news.news_header}</Link>
    },
    { path: "is_published", label: "Publish" },
    { path: "creation_timstamp", label: "Creation Date" },
    {
      label:"edit",
      key: "edit",
      content: news => <Link to={`/news/${news.id}`} className="edit-a"><div className="edit-box"><img alt="edit" src={require("../../assets/images/edit.svg")} /> </div></Link>
    },
    {
      
      label: "Delete",
      key: "delete",
      content: news => (<button onClick={()=>{this.hanldeDelete(news.id)}} className="edit-box"><img alt="delete" className="mb-1" src={require("../../assets/images/delete-row.svg")} /> </button>)
    }
  ];

    state = {

      news:["",""],
      currentPage: 1,
      pageSize: 4,
      searchQuery: "",
      autoComplete:[],
      loading:true
    };

    async componentDidMount() {
      const news=await getNews("");
      if(news)
      this.setState({news},()=>{this.setState({loading:false})});
    }

    async hanldeDelete(id){
      let confirm=window.confirm(`Are you sure you want to delete this What’s New record?`);
      if(confirm)
      {
        const originalNews = this.state.news;
        const news = originalNews.filter(n => n.id !== id);
        this.setState({ news });
        await deleteNews(id);
      }
    }

    async onSearchChange(event){
      const news=await getNews(event.target.value);
       this.setState({news});
    }

    render() 
    {
        return (
          <React.Fragment>
            <main className="mainstyle  col-lg-9 col-md-8 col-sm-7 mt-4 mb-4" >
              <Title title="What's New"  calling={"news"} onSearchChange={(e)=>{this.onSearchChange(e)}} autoComplete={this.state.autoComplete}>
              </Title>
              <div className="grid-display">
              <ClipLoader
                  size={50}
                  color={"#183D6E"}
                  loading={this.state.loading}
              />
              </div>
              <div className=" ml-4">
              <div className="row " hidden={ this.state.loading? true : false } >
                {this.state.news.length>0? <Table columns={this.columns} data={this.state.news}/>:<div className="centerdiv col-12 anchor-table">There is no news avilable</div>}
              </div>
              </div>
            </main>
          </React.Fragment>
        );
    }

}
export default WhatsNew;