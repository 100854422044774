import React from "react";

function AddField({ value,label, btnName, placeholderTitle, onNameChange, onUrlChange,onClick,error }) {
    
  return (
    // <div className=" width-percentage-70 ">
    <React.Fragment>
      <label className="label-form">{label}</label>
      <fieldset className="form-group">
        <input
          type="text"
          placeholder="Add source name"
          className="input-title col-5 add-field-input"
          value={value.source_name}
          onChange={onNameChange}
        />

        <input
          type="url"
          value={value.source_url}
          placeholder="Add source url"
          className="input-title col-5 add-field-input"
          onChange={onUrlChange}
        />

        <button type="button" className="plus-btn m-0"  onClick={onClick}>
          <i className="fa fa-plus"></i>
        </button>
      </fieldset>
      {error && <div className="alert alert-danger">{error}</div>}
      </React.Fragment>
    // </div>
  );
}

export default AddField;
