import React from "react";
import Joi from "../../../node_modules/joi-browser";
import Form from "../common/form/form";
import {getNewsDetails,updateNews, addNews} from "../../services/newsServices"
import Title from "../common/Title/title";

class WhatsNewForm extends Form {
  state = {
    data: { Title: "",
            Body: "",
            is_featured:false,
            is_listing_feature:false,
            news_image:null,
            is_published:false
        },
    isFound: true,
    errors: {}
  };

  schema = {
    Title: Joi.string().required(),
    Body: Joi.string().required(),
    is_featured: Joi.boolean().required(),
    is_listing_feature:Joi.boolean().required(),
    is_published:Joi.boolean().required(),
    news_image:Joi.any()
  };

   doSubmit = async () => {
     try{
          let confirm=window.confirm("Are you sure you want to proceed?");
          if(confirm)
          {
              if(this.props.match.params.id !== "new")
              await  updateNews(this.props.match.params.id,this.state.data);
              else
              await  addNews(this.state.data);

              this.props.history.push("/whatsnew");
          }
     }
     catch(e){
            console.log(e);
     }
  };

  async componentDidMount() {
    const newsId = this.props.match.params.id;
    if (newsId === "new") return;

    const news = await getNewsDetails(newsId);
    if(news == null)
    {
      this.setState({isFound:false})
      return 
    }

    this.setState({ data: this.mapToViewModel(news) });
  }


  mapToViewModel(news) {
    return {
      Title: news.news_header,
      Body: news.news_details,
      news_image: news.news_image,
      is_featured:news.is_featured,
      is_listing_feature:news.is_listing_feature,
      is_published:news.is_published
    };
  }

  render() {
    if(this.state.isFound)
    return (
      <React.Fragment>
      <main className="mainstyle  col-lg-9 col-md-8 col-sm-7 mt-4 mb-4" id="details-scroll">
      <Title title="What's New"></Title>
      <div className="mr-4 ml-4">
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("Title", "Title", "text","Add title here")}
          {this.renderInput("Body", "Body text","","Add body text here")}
          {this.renderInput("news_image",  "Featured image", "file")}
          <div className="row">
          {this.renderInput("is_featured",  "Feature in homepage",  "checkbox")}
          {this.renderInput("is_listing_feature",  "Feature in what's new page",  "checkbox")}
          {this.renderInput("is_published",  "Publish",  "checkbox")}
          </div>
          {this.props.match.params.id !== "new"? this.renderButton("Update") : this.renderButton("Add")}
        </form>
      </div>
      </main>
      </React.Fragment>
    );

   return <div>There is no such id</div>
  }
}

export default WhatsNewForm;
