import React, { Component } from "react";
import _ from "lodash";
import "./table.css";

class TableBody extends Component {

  renderCell = (item, column) => {
    let date = _.get(item, column.path);
    if (column.label === "Creation Date") {
      var convertedStartDate = new Date(date);
      var day = convertedStartDate.getDate();
      var month = convertedStartDate.getMonth() + 1;
      var year = convertedStartDate.getFullYear();
      var fullDate = year + "/" + month + "/" + day;
      date = fullDate;
      return date;
    } else if (column.content) return column.content(item);

    
    return  _.get(item, column.path)?"Yes":"No";
  };

  render() {
    const { data, columns } = this.props;

    return (
      <tbody className="col-12" id="style-2">
        {data?.map((item, i) => (
          <tr className="row" key={i}>
            {columns.map((column, i) => {
              if (column.label === "Title")
                return (
                  <td className="col-4 col-lg-5" key={i}>
                    {/* <div className="col-2 col-lg-1 div-margin">
                      <div className="title-box"></div>
                    </div> */}
                    <div className="title-flex"> {this.renderCell(item, column)}</div>
                  </td>
                );
              if ( column.label === "Delete")
              return <td className="td-delete col-1" key={i}> {this.renderCell(item, column)}</td>;
              
              if(column.label === "edit" )
              return <td className="td-edit col-1" key={i}> {this.renderCell(item, column)}</td>;

              if(column.label === "Creation Date")
              return <td className="col-3  publishing-padding" key={i}> {this.renderCell(item, column)}</td>;
              return <td className="col-3 col-lg-2 publishing-padding" key={i}> {this.renderCell(item, column)}</td>;
            })}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
