import React, { Component } from 'react';
import NavBar from "./components/navBar/navBar";
import { Route, Switch } from "react-router-dom";
import WhatsNew from "./components/whatsNew/whatsNew";
import Tips from "./components/heartTips/tips";
import './App.css';
import WhatsNewForm from './components/whatsNew/whatsNewDetails';
import TipsForm from './components/heartTips/tipsDeatils';
import Settings from './components/settings/settings';
import Campaign from './components/campaign/campaign';
import CampaignForm from './components/campaign/campaignDetails';
import Notification from './components/notifications/notifications';
import Login from './components/logIn/logIn';
import store from "../node_modules/store2";

  class App extends Component{

  state={
    loggedIn:false
  }
 
  componentDidMount(){
   let data= store.local.get('currentUser');
   if(data)
   this.setState({loggedIn:true});
   else
   this.setState({loggedIn:false});
  }
  
  onChange=(logged)=>{
    if(logged)
    this.setState({loggedIn:true});
    else
    this.setState({loggedIn:false});
  }

 render(){
  return (
      <div className="">
          <div className="row full-width">

              <Route path=""  component={(props) => <NavBar {...props} loggedIn={this.state.loggedIn} handleLogout={(logged)=>{this.onChange(logged)}} />} />
              <Switch>
                <Route path="/news/:id" component={WhatsNewForm} />
                <Route path="/whatsnew" component={WhatsNew} />
                <Route path="/home" component={WhatsNew} />
                <Route path="/tips/:id" component={TipsForm} />
                <Route path="/tips" component={Tips} />
                <Route path="/campaign/:id" component={CampaignForm}/>
                <Route path="/campaign" component={Campaign}/>
                <Route path="/notifications" component={Notification} />
                <Route path="/settings" component={Settings} />
                <Route path=""  component={ this.state.loggedIn? WhatsNew : (props) => <Login {...props} onChange={(logged)=>{this.onChange(logged)}} />}/>
              </Switch>
        </div>
      </div>
  
  );
  }
}

export default App;
