import React from "react";
import "./settings.css"

const SettingsInput =(props)=>{

    return(
        <div className="form-group ">
            <label className="settings-label" htmlFor={props.label}>{props.label}</label>
            <input  type={props.type} 
                    value={props.value} 
                    className="settings-input col-10 col-lg-11"  
                    placeholder={props.placeholder} 
                    onChange={props.label==="Password"? (event)=>props.onOldChange(event) :(event)=>props.onChange(event,props.label)} 
                    readOnly={ (props.label==="Password" && props.edit===false ?  true : false) || props.label==="Email" }>

            </input>
            <label  hidden={ props.label==="Password" ?  false : true } 
                    className="show-label col-1" 
                    onClick={props.onclick}>edit
            </label>
            <input  hidden={ props.label==="Password" && props.edit===true ?  false : true }  
                    type={props.type}
                    placeholder={"New password"} 
                    className="settings-input col-10 col-lg-11 mt-3"
                    onChange={(event)=>props.onNewChange(event)}>
                   
            </input>
            <input  hidden={ props.label==="Password" && props.edit===true ?  false : true }  
                    type={props.type}
                    placeholder={"Confirm password"} 
                    className="settings-input col-10 col-lg-11 mt-3"
                    onChange={(event)=>props.onConfirmChange(event)}>
                   
            </input>
            {props.error && <div className="alert alert-danger col-10 col-lg-11">{props.error}</div>}
        </div>
    )
}

export default SettingsInput;