import React, { Component } from "react";
import './table.css';

class TableHeader extends Component {

  render() {
    return (
      <thead className="col-12">
        <tr className="row">
          {this.props.columns.map((column,i) => {
              if(column.label==="Title")
                return <th className="col-4 col-lg-5" key={i}>
                {/* <div className="col-2 col-lg-1 div-margin">
                <div className="title-box"></div>
                </div> */}
                <div className="title-flex"> {column.label}</div>
                </th>
              
              if(column.label==="Delete")
                return <th className="td-delete" key={i}>
                
                </th>

              if(column.label==="edit")
                return <th className="white-color"  key={i}>
                  {column.label}
                </th>

              if(column.label === "Creation Date")
              return <th className="col-3 publishing-padding" key={i}>{column.label}
              </th>

              return <th className="col-3 col-lg-2 publishing-padding"  key={i}>{column.label}</th>
  })}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
