import React from "react";
import './searchBox.css';
import 'font-awesome/css/font-awesome.min.css';


const SearchBox = (props) => {

return (
    <div className="search_div p-2 row ">
        <i className="fa fa-search searchicon col-1"></i>
        <input id="serach_input"  placeholder="Search Posts" onChange={(e)=>{props.onSearchChange(e)}} className=" searchWord col-11" type="text"/>
        {/* <ul className="search-ul" hidden={props.autoComplete.length === 0 ? true : false}> 
            {props.autoComplete.map((suggestion,i) => 
            {return <Link to={`/${props.calling}/${suggestion.id}`}>
                        <li key={i} className="suggestion-list">{suggestion.lower}</li>
                    </Link>})} 
        </ul> */}
    </div>
);

}
export default SearchBox;