
import { apiUrl } from "../config.json";
import axios from "axios";
import store from "../../node_modules/store2";


export async function get_(url)
{
    let token= store.local.get('currentUser');
    if(!token)
    {
        window.location.pathname ="/";
        return;
    }
    try{
        let res= await axios.get(url,{headers:{'x-auth-token':token}});

        if(res.data.status ===200)
        return res.data;
        else if (res.data.status === 403)
        {
            let refresh_token= store.local.get('refresh_token');
            const refresh_url=apiUrl+"/token/refresh";
            let req_obj={token: refresh_token};
            let refresh_res= await axios.post(refresh_url, req_obj);
            if (refresh_res.data.status===200)
            {
                store.local.set('currentUser', refresh_res.data.token);
                store.local.set('refresh_token', refresh_res.data.refreshToken);
                res= await axios.get(url,{headers:{'x-auth-token':refresh_res.data.token}});
                if(res.data.status ===200)
                return res.data;
                return false;
            }

        }
        else
        {
            store.local.remove('currentUser');
            store.local.remove('refresh_token');
            window.confirm("Something went wrong");
            window.location.pathname = "/";
            return false;
        }
    }
    catch(e)
    {
        console.log(e);
        window.confirm("Session Expired");
        store.local.remove('currentUser');
        store.local.remove('refresh_token');
        window.location.pathname = "/";
        return false;
    }

  
}


export async function post_(url,obj)
{
    let token= store.local.get('currentUser');
    if(!token)
    {
        window.location.pathname = "/";
        return;
    }
    try{
        let res= await axios.post(url,obj,{headers:{'x-auth-token':token}});

        if(res.data.status ===200)
        return res.data;
        else if (res.data.status === 403)
        {

            let refresh_token= store.local.get('refresh_token');
            const refresh_url=apiUrl+"/token/refresh";
            let req_obj={token: refresh_token};
            let refresh_res= await axios.post(refresh_url, req_obj);
            if (refresh_res.data.status===200)
            {
                store.local.set('currentUser', refresh_res.data.token);
                store.local.set('refresh_token', refresh_res.data.refreshToken);
                res= await axios.post(url,obj,{headers:{'x-auth-token':refresh_res.data.token}}); 
                if(res.data.status ===200)
                return res.data;
                return false;
            }
        
        }
        else
        {
            store.local.remove('currentUser');
            store.local.remove('refresh_token');
            window.confirm("Something went wrong");
            window.location.pathname = "/";
            return false;
        }

    }
    catch(e)
    {

      console.log(e.message);
      if(e.message==="Request failed with status code 403")
      {
        window.confirm("Session Expired");
        store.local.remove('currentUser');
        store.local.remove('refresh_token');
        window.location.pathname = "/";
      }
      else
      {
        window.confirm("Something went wrong");
        window.location.pathname = "/";
      }
      return false;

    }

  
}

