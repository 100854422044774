import React from "react";
import Form from "../common/form/form";
import './notification.css';
import Joi from "joi-browser";
import Title from "../common/Title/title";
import {pushNotification} from "../../services/notificationServices";
import store from "../../../node_modules/store2";
class Notification extends Form {

state = {
    data: { title: "",
            body: "",
        },
    errors: {},
    token:""
};

schema = {
  title: Joi.string().required(),
  body: Joi.string().required(),
};

componentDidMount() {
  let token= store.local.get('currentUser');
  if(!token)
  {
    this.props.history.push("");
    window.location.reload();
    return;
  }
  this.setState({token})
;}

doSubmit = async () => {
    try{    
      let confirm=window.confirm("You are about to push a notification");
          if(confirm)
          {
            let sent=await pushNotification(this.state.data.title,this.state.data.body,this.state.token);
            //this.props.history.push("/whatsnew"); 
            //window.location.reload(false);
            if(sent)
            {
            window.confirm("Notification sent successfully");
            }
            else
            {
            window.confirm("Something went wrong, Please try again");
            }
          }
    }
    catch(e){
            console.log(e);
    }
};

render() 
{
        return (
          <React.Fragment>
            <main className="mainstyle  col-lg-9 col-md-8 col-sm-7 mt-4 mb-4" id="details-scroll">
            <Title title="Push notifications">
            </Title>
            <div className="mr-4 ml-4 ">
                <form onSubmit={this.handleSubmit}>
                {this.renderInput("title", "Title", "text","Add Title here")}
                {this.renderInput("body", "Body", "text","Add Body here")}
                {this.renderButton("Push")}
                </form>
            </div>
            </main>
          </React.Fragment>
        );
    }
}

export default Notification;